@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

.privacy {
    &__title {
        color: $nav-Background;
        text-align: center;
        font-size: 4rem;
    }
    &__subheading {
        padding: 0.25rem 0;
        margin-left: 1.75rem;
        font-size: 1.75rem;
        font-weight: 500;
    }
    &__text {
        color: $primary-Text;
        padding: 0rem 0.75rem;
        margin-left: 1.25rem;
        font-size: 1.25rem;
        font-weight: 500;
    }
}