@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;



.about {
    display: flex;
    flex-direction: column;
    @include desktop {
        flex-direction: row;
    }
    &__wrapper {
        margin: 2rem auto;
        text-align: center;
    }

    &__image {
        align-self: center;
        width: 80%;
        max-width: 100%;
        height: auto;
        
    }
}

.content {
    &__wrapper {
        text-align: center;
        @include desktop {
            width: 70%;
            margin-right: 1rem;
        }
    }

    &__title {
        font-size: 3rem;
        margin-top: -0.75rem;
        @include tablet {
           font-size: 3.25rem;
        }
        @include desktop {
            padding-top: 6rem;
        }
    }

    &__text {
        color: $primary-Text;
        padding: 0.5rem 2rem;
        @include tablet {
            font-size: 1.75rem;
        }
    }
}

.curb {
    &__wrapper {
        margin: 2rem auto;
        text-align: center;
    }

    &__image {
        align-self: center;
        width: 80%;
        max-width: 100%;
        height: auto;

        @include desktop {
            width: 90%;
        }
        
    }
}