@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;
.header {
    background-color: $nav-Background;
    &__nav {
        display: flex;
        flex-direction: row;
        padding: 1rem 0rem;
        @include tablet {
            display: flex;
            justify-content: space-between;
        }
    }
    &__logo {
        width: 10rem;
        height: auto;
        margin-left: 0.5rem;
        @include tablet {
            height: 4.5rem;
            width: 18rem;
        }
        @include desktop {
            width: 18rem;
        }
    }
    &__list {
        display: none;
        list-style: none;
        &-item {
            color: $primary-Background;
            &--wrapper {
                text-decoration: none;
            }

            &--image {
                width: 2rem;
                height: 2rem;
            }
        }
        @include tablet {
            display: flex;
            flex-direction: row;
            padding-right: 2rem;
            &-item {
                font-size: 2rem;
                margin: 0 1rem;
            }
        }
    }
    &__hamburger {
        height: 2.25rem;
        width: 2.25rem;
        padding-top: 1.5rem;
        padding-right: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        &-wrapper {
            @include tablet {
                display: none;
            }
        }
    }
}