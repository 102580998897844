@use './variables.scss' as *;
@use './mixins.scss' as *;
@use './typography' as *;

body {
    background-color: $primary-Background;
    max-width: 1600px;
    margin: 0 auto;
    font-family: "Darker Grotesque";
    cursor: url("../../assets/images/icons/grayBulb.svg"), text;
}