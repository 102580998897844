@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

body {
    color: $nav-Background;
}


.image {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__picture {
        border: 3px solid $nav-Background;
        max-width: 100%;
        width: 90%;
        height: auto;
        margin: 4rem;
        @include tablet {
            width: 70%;
        }
        @include desktop {
            margin: 6rem auto;
            width: 60%;
        }
    }
}

.brand__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-slogan {
        &--container {
            display: flex;

        }
        &--title {
            margin: -2rem auto;
            text-align: center;
            font-size: 1.25rem;
            @include desktop {
                font-size: 3rem;
            }
        }
    }
    &-image {
        &--wrapper {
            margin: 0 auto;
            text-align: center;
        }
        &--picture {
            padding: 2rem 0;
            text-align: center;
            width: 90%;
            max-width: 100%;
            @include desktop {
                padding: 6rem 0;
            }
        }
    }
}