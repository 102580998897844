@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

body {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
    margin-top: -5rem;
    max-width: 100%;
}

.menu__modal {
    position: fixed;
    margin: 0 auto;
    margin-top: 5rem;
    z-index: 10;
    max-width: 100%;
    background-color: $nav-Background;
    right: 0;
    left: 0;
    &-close {
        height: 2rem;
        width: 2rem;
        position: fixed;
        right: 0;
        top: 0;
        padding-right: 1rem;
        padding-top: 1rem;
    }
    &-list{
        display: flex;
        flex-direction: column;
        margin: 2rem auto;
        padding-left: 2rem;
        padding-right: 2rem;
        align-items: center;
        list-style: none;
        text-decoration: none;
        a {
            text-decoration: none;
        }
        &--item {
            color: $primary-Background;
            font-size: 3rem;
            line-height: 4rem;
            &-logo {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

// .cart {
//     z-index: 30;
//     color: $primary-Background;
// }