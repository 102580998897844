@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

footer {
    position: absolute;
    bottom: -26rem;
    left: 0;
    right: 0;
}


.footer {
    background-color: $footer-Background;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include desktop {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6rem 0 6rem;
    }
    &__contact {
        padding: 2rem;
        text-align: center;
        font-size: 2rem;
        &-instagram {
            height: 2.75rem;
            width: 2.75rem;
            @include desktop {
                padding-top: 4.25rem;
                padding-left: 9.5rem;
            }
        }
    }
    
    &__legals {
        flex-direction: column;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        padding: 0rem 1rem;
        @include tablet {
            font-size: 1.75rem;
        }
        @include desktop {
            align-self: baseline;
            justify-self: last baseline ;
            margin-top: 0.4rem;
        }
        a{
            text-decoration: none;
            color: $primary-Background;
            &:hover {
                color: $nav-Background;
            }
        }
    }
    &__copy {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        padding: 0.5rem;
        color: $primary-Background;
        a{
            text-decoration: none;
            color: $primary-Background;
            &:hover {
                color: $nav-Background;
            }
        }
        @include tablet {
            font-size: 1.75rem;
        }
        @include desktop {
            padding-top: 3rem;
            align-self: baseline ;
        }
    }
}