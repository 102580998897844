@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

.product__details {
    text-align: center;
   &-wrapper {
    border: 3px solid ;
    @include mobile {
        margin: 2rem 0.2rem;
    }
    @include desktop-Plus {
        margin: 2rem;
    }
    &--link {
        text-decoration: none;
    }
   }
    &-image {
        @include mobile {
            padding: 0.5rem;
            width: 12.5rem;
            height: 12.5rem;
        }
        @include tablet {
            padding: 2rem;
            width: 21.875rem;
            height: 25rem;
        }
    }
    &-name {
       color: $nav-Background;
       font-size: 2rem;
    }
    &-price {
        color: $nav-Background;
        font-size: 1.75rem;
    }
}