@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

body {
    position: relative;
}

.parent__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem auto;
    @include tablet {
        position: relative;
    }
}

.back__button {
    position: absolute;
    top: 7rem;
    left: 2rem;
    @include tablet {
        top: -2rem;
    }
}

.button {
    color: $nav-Background;
    background-color: $primary-Background;
    border: 2px solid $nav-Background;
    padding: 1rem 2rem;
    margin-top: 2rem;

    @include tablet {
        position: absolute;
        top: 20rem;
        right: 7.5rem;
    }

    @include inbetween {
        right: 12rem;
    }

    @include desktop {
        position: absolute;
        right: 23.5rem;
    }

    @include desktop-Plus {
        right: 27.5rem;
    }
}