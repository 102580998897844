@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

.shopping__cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
        margin-top: 6rem;
        font-size: 2.2rem;
        @include desktop {
            font-size: 4rem;
        }
    }

    &-list {
        list-style: none;
        margin-right: 1.5rem;
    }
}

.product {
    &__wrapper {
        border: 2px solid $nav-Background;
    text-align: center;
    padding: 2rem 3rem;
    }
    &__name {
        font-size: 2rem;
    }
    &__price, &__quantity {
        font-size: 1.75rem;
    }
    
}

.checkout {
    &__wrapper {
        margin-top: 6rem;
        text-align: center;
        @include desktop {
            margin: 10rem 0;
        }
    }

    &__button {
        color: $primary-Background;
        background-color: $nav-Background;
        border: 2px solid $nav-Background;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        font-weight: 500;
    }
}