@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/global.scss' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/typography' as *;

.product {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        @include tablet {
            flex-direction: row;
        }
    }

    &__image {
        &-image {
            @include mobile {
                padding: 0.5rem;
                width: 12.5rem;
                height: 12.5rem;
            }
            @include tablet {
                padding: 2rem;
                width: 21.875rem;
                height: 25rem;
            }
        }
    }
    &__content {
        &-container {
            border: 1px solid $nav-Background;
            @include tablet {
                margin-right: 1.5rem;
                margin-top: -4rem;
            }
        }
        &-title {
            text-align: center;
            font-size: 2em;
        }
        &-description{
            font-size: 1.25rem;
            padding: 0.25rem 1rem;
        }
        &-price {
            text-align: center;
            font-size: 1.75rem;
        }
    }
}